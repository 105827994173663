import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import IndexContainer from '../containers/IndexContainer'
import sortBy from 'lodash/sortBy'

class IndexPage extends React.Component {
  render() {
    const [{ node: siteContents }] = get(this, 'props.data.allContentfulSiteContents.edges')
    const products = get(this, 'props.data.allContentfulProducts.edges').map(({ node }) => node)
		const partnersGroups = get(this, 'props.data.allContentfulPartners.group').map((group) => {
		return {
			key: group.edges[0].node.category,
			order: group.edges[0].node.order,
			items: group.edges.map(({ node }) => node)
		}
	});
		const partnersByCategory = sortBy(partnersGroups, 'order')
    const solutions = get(this, 'props.data.allContentfulSolutions.edges').map(({ node }) => node)
    const jobDescriptions = get(this, 'props.data.allContentfulJd.edges').map(({ node }) => node)
    const updates = get(this, 'props.data.allContentfulUpdates.edges').map(({ node }) => node)
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    return (
			<>
				<Helmet title={siteTitle} />
				<IndexContainer 
          language="ko"
          location={this.props.location}
					siteContents={siteContents}
					products={products}
					partners={partnersByCategory}
					solutions={solutions}
					jobDescriptions={jobDescriptions}
					updates={updates}
				/>
			</>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQueryKo {
    site {
      siteMetadata {
        title
      }
    }
	 allContentfulProducts(sort: { fields: [order], order: ASC }, filter: {node_locale: {eq: "ko-KR"}}) {
			edges {
				node {
					title
					url
					description {
						description
					}
					image {
						file {
							url
							details {
								image {
									height
									width
								}
							}
						}
            sizes {
              srcSet
            }
					}
				}
			}
		}
		allContentfulSiteContents(filter: {node_locale: {eq: "ko-KR"}}) {
			edges {
				node {
					description {
						description
					}
					companyName
          contactSales
					address1
					address2
					menu1
					menu2
					menu1Short
					menu2CtAurl
					menu2Cta
					menu2Short
					menu3
					menu3Categories
					menu3CtAurl
					menu3Cta
					menu4
					menu3Short
					menu4Cta
					menu4JdTitle
					menu4Short
					menu4Description {
						menu4Description
					}
					menu4Tags
					menu5
					menu5Short
          ogTitle
          ogDescription {
            ogDescription
          }
          ogImage {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
				}
			}
		}

    allContentfulSolutions(sort: { fields: [order], order: ASC }, filter: {node_locale: {eq: "ko-KR"}}) {
			edges {
				node {
					title
					description {
						description
					}
					image {
						file {
							url
							details {
								image {
									height
									width
								}
							}
            }
            sizes {
              srcSet
            }
					}
				}
			}
    }
		allContentfulPartners(filter: {node_locale: {eq: "ko-KR"}}, sort: {fields: order, order: ASC}) {
			group(field: category) {
				edges {
					node {
						logo {
							file {
								details {
									image {
										height
										width
									}
								}
								url
							}
              sizes {
                srcSet
              }
						}
						name
						order
						category
					}
				}
			}
		}
		allContentfulUpdates(filter: {node_locale: {eq: "ko-KR"}}, sort: {order: DESC, fields: publishDate}, limit: 7) {
			edges {
				node {
					title
					url
					publishDate
				}
			}
		}
		allContentfulJd(sort: {fields: order, order: ASC}, filter: {node_locale: {eq: "ko-KR"}}) {
			edges {
				node {
					id
					position
					url
					description {
						description
					}
				}
			}
		}
  }
`
